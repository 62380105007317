<template>
  <v-card class="ma-3">
    <v-card-subtitle class="primary white--text text-center">
      Архивные тех. карты
    </v-card-subtitle>
    <CardsTable
        url="/manufacture/card/index-archive"
        :queryParams="{ orgId: orgId }"
        :key="cardsTableUK"
        >
      <template v-slot:select="{ card }">
        <v-checkbox :value="card.id" v-model="selectedCards"></v-checkbox>
      </template>
      <template v-slot:name="{card}">
        <router-link :to="cardViewUrl(card)">{{card.name}}</router-link>
      </template>
    </CardsTable>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CardsTable from './components/CardsTable.vue'

  export default {
    components: { CardsTable },
    data: () => ({
      selectedCards: [],
      cardsTableUK: 0,
    }),
    computed: {
      ...mapState('organization', {
        orgId: state => state.profile.id,
      }),
    },
    methods: {
      cardViewUrl(card) {
        return card.module.baseUrl + '/card/view?uuid=' + card.cardUuid
      },
    },
  }
</script>
